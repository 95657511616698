var ENV = 'production';

var TITLE = "CargoBikeCity-OpenBike";
var NAME = "obike.cargobikometer.de";
var SYSTEM_URL = "https://obike-api.cargobikometer.de";
var API_ROOT = SYSTEM_URL + "/api";
var GBFS_URL = SYSTEM_URL + "/gbfs/gbfs.json";

var DEFAULT_LOCATION = [52.43913 , 13.54786];
var DEFAULT_ZOOM = 15;

var I18N_MESSAGE_OVERRIDE = {
  "en": {
    "message": {
      "about": {
        "html": "An experimental map for the game CargoBikeCity from <a href='https://sai-lab.de'>SAI-Lab Berlin</a>"
      }
    }
  },
  "de": {
    "message": {
      "app": {
        "unvalidated-message": "Sie können noch keine virtuellen Fahrräder in CargoBikeCity benutzen, da ihr Account noch nicht freigeschalten wurde. Bitte <a href='mailto:CargoBikeCity-OpenBike@t-online.de'>senden Sie uns</a> Ihren rechts oben angezeigten Nutzernamen, um sich freischalten zu lassen."
      },
      "about": {
        "html": "Ein experimenteller Stadtplan für das Spiel CargoBikeCity vom <a href='https://sai-lab.de'>SAI-Lab Berlin</a>"
      }
    }
  }
};

var SUPPORT_TYPE = 'zammad';
var SUPPORT_URL = 'https://support.obike.cargobikometer.de';

var SENTRY_DSN = '';

module.exports = { ENV, TITLE, NAME, API_ROOT, GBFS_URL, DEFAULT_LOCATION, DEFAULT_ZOOM, I18N_MESSAGE_OVERRIDE, SUPPORT_TYPE, SUPPORT_URL, SENTRY_DSN };
